class Site {
    public static onLogin(): void {
        $(() => {
            let $form = $('.loginform');
            let $error = $('.loginform__error');

            $form.on('submit', (e) => {
                e.preventDefault();

                let data = $form.serializeArray();

                $.post('/logowanie', data, (r) => {
                    if (r.status == 'ok') {
                        window.location.href = '/';
                    } else if (r.status == 'error') {
                        $error.show();
                        $error.text(r.error);
                    }
                });
            });
        })
    }

    public static init(): void {
        (<any> window).Site = Site;
    }
}

Site.init();
